import { ApiRequestState } from 'lib/apis';
import {
  Children,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState
} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { StyleSheet } from 'types';

import { FlexTableCell } from 'components/atoms/table/TableDataCell';

type SuspenseTableProps = {
  status: ApiRequestState<unknown>['status'];
};

/**
 * Create a table object that suspends its contents while pending or failing.
 *
 * @param props A collection of component properties.
 * @returns A JSX element representing a table.
 */
export const SuspenseTable = ({
  children,
  status
}: PropsWithChildren<SuspenseTableProps>) => {
  const [head, setHead] = useState<ReactNode>();
  const [body, setBody] = useState<ReactNode>();

  useEffect(() => {
    Children.forEach(children, child => {
      if (!isValidElement(child)) {
        return;
      }

      switch (child.type) {
        case 'thead':
          return setHead(child);
        case 'tbody':
          return setBody(child);
      }
    });
  }, [children]);

  /**
   * Generate the table head component.
   *
   * @returns A JSX element.
   */
  const getTableHead = (): ReactNode => {
    return head;
  };

  /**
   * Generate the table body component.
   *
   * @returns A JSX element.
   */
  const getTableBody = (): ReactNode => {
    switch (status) {
      case 'pending':
        return (
          <tr>
            <FlexTableCell>
              <ClipLoader cssOverride={styles.clipLoader} color="#fc8124" />
            </FlexTableCell>
          </tr>
        );
      case 'success':
        return body;
      case 'error':
        return (
          <tr>
            <FlexTableCell>
              Failed to fetch results. Please refresh the page to try again.
            </FlexTableCell>
          </tr>
        );
    }
  };

  return (
    <table className="table">
      {getTableHead()}
      {getTableBody()}
    </table>
  );
};

const styles: StyleSheet = {
  clipLoader: {
    marginBottom: 12
  }
};
