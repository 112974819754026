import { PropsWithChildren } from 'react';
import { StyleSheet } from 'types';

/**
 * Render a flexible table cell, that occupies the entire table width.
 *
 * @param props A collection of component properties.
 * @returns A JSX element representing a table data cell.
 */
export const FlexTableCell = ({ children }: PropsWithChildren<{}>) => {
  return <td style={styles.td}>{children}</td>;
};

const styles: StyleSheet = {
  td: {
    display: 'flex',
    justifyContent: 'center'
  }
};
