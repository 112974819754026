const mFormatter = new Intl.NumberFormat(undefined, {
  style: 'unit',
  unit: 'meter',
  unitDisplay: 'short'
});

const kmFormatter = new Intl.NumberFormat(undefined, {
  style: 'unit',
  unit: 'kilometer',
  unitDisplay: 'short',
  maximumFractionDigits: 1
});

/**
 * Formats a given distance in meters using a predefined formatter.
 *
 * @param distance - The distance in meters to be formatted.
 * @returns The formatted distance as a string.
 *
 * @example
 * formatMeters(10); //=> 10 m
 */
export const formatMeters = (distance: number) => {
  return mFormatter.format(distance);
};

/**
 * Formats a given distance in kilometres using a predefined formatter.
 * @param distance  The distance (in metres).
 * @returns The formatted sitance as a string.
 *
 * @example
 * // returns "3.5 KM"
 * formatMetresAsKilometres(3456.789);
 */
export const formatMetresAsKilometres = (distance: number) => {
  return kmFormatter.format(distance / 1000);
};
