import { BrowserRouter } from 'react-router-dom';
import Router from 'Router';
import ErrorBoundary from 'providers/errorBoundary';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { useState } from 'react';

import MainGrid from 'components/shared/Grids/MainGrid';
import RotatePhone from 'components/shared/RotatePhone';
import 'scss/main.scss';
import LoadingModal, {
  LoadingProps
} from 'components/shared/LoadingModal/LoadingModal';

const TRACKING_ID = 'UA-243463846-1'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

if (process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize({
    id: Number(process.env.REACT_APP_HOTJAR_ID),
    sv: Number(process.env.REACT_APP_HOTJAR_SV)
  });
}

console.log('%cVersion: 1.1.2', 'color: green;');

function App() {
  const [loading, setLoading] = useState<LoadingProps>({
    open: false,
    title: '',
    description: '',
    status: ''
  });

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <MainGrid>
          <RotatePhone />
          <LoadingModal
            open={loading.open}
            title={loading.title}
            description={loading.description}
            status={loading.status}
          />
          <Router setLoadingCallback={setLoading} />
        </MainGrid>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;
