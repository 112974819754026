import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommonProps } from 'Router';
import {
  getLandQuery,
  isApiStatus,
  isLandQueryResult,
  LandQueryResult,
  processLandQuery
} from 'lib/apis';

import Opportunities from './Opportunities';

import './index.scss';

const OpportunitiesPage = ({ setLoadingCallback }: CommonProps) => {
  const navigate = useNavigate();
  const [data, setData] = useState<LandQueryResult | undefined>(undefined);
  const [status, setStatus] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(true);
  const polygon = useMemo(() => {
    const strObj: { [key: string]: string } | null = JSON.parse(
      localStorage.getItem('selectedArea') || '{}'
    );

    const res = [];

    if (strObj) {
      for (let i = 0; i < Object.keys(strObj).length / 2; i++) {
        res.push({
          x: Number(strObj[`x${i}`]),
          y: Number(strObj[`y${i}`])
        });
      }
    }

    return res;
  }, []);

  useEffect(() => {
    const acreage = Number(localStorage.getItem('acres') || 0);

    const pollUrl = async (queryId: number) => {
      const response = await getLandQuery(queryId);

      if (isLandQueryResult(response)) {
        setData(response.data);
        setLoading(false);
      } else {
        if (isApiStatus(response)) {
          console.log('Status:', response.data.status);
          setStatus(response.data.status);
        }

        setTimeout(() => pollUrl(queryId), 250);
      }
    };

    if (loading) {
      processLandQuery(polygon, acreage, true).then(x => {
        pollUrl(x.id);
      });
    }
  }, [loading]);

  useEffect(() => {
    setLoadingCallback({
      open: loading,
      title: 'Please wait as we make your land work for you.',
      description:
        'Your privacy is important to us. Your land will only be shared with project partners once applied.',
      status
    });
  }, [loading, status]);

  useEffect(() => {
    const selectedArea = localStorage.getItem('selectedArea');

    if (!selectedArea) {
      navigate('/');
    }
  }, []);

  return <Opportunities data={data} />;
};

export default OpportunitiesPage;
