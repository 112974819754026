import { useRef, useState, useEffect, LegacyRef, useCallback } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import turfArea from '@turf/area';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxgl from 'mapbox-gl';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MAP_BOX_API_TOKEN } from 'lib/constants/envConstants';
import { opportunities } from 'lib/constants/routes';
import {
  NotificationManager,
  NotificationContainer
  // @ts-ignore
} from 'react-notifications';
import debounce from 'lodash.debounce';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useAnalyticsEventTracker from 'lib/helpers/useAnalyticsEventTracker';
import { isMobile, isSafari, isIOS, isChrome } from 'react-device-detect';
import CustomPolygonDraw from 'lib/mapbox/customPolygonDraw';
import {
  ForwardGeocodeResult,
  getForwardGeocode,
  getMapPageContent,
  getValidArea,
  MapPage
} from 'lib/apis';

import drawIcon from './imgs/draw.svg';
import helpIcon from './imgs/help.svg';
import moveIcon from './imgs/move.svg';
import outlineIcon from './imgs/outline.svg';
import clearIcon from './imgs/trash.svg';
import zoomInIcon from './imgs/zoom-in.svg';
import zoomOutIcon from './imgs/zoom-out.svg';
import mapDrawStyles from './mapDrawStyles';
import VideoPlayer from 'components/pages/MapDraw/Video';
import ClearConfirmModal from 'components/pages/MapDraw/ClearConfirmModal';
import searchIcon from 'components/pages/Home/imgs/search.svg';

import 'react-notifications/lib/notifications.css';

import './MapDraw.scss';

// @ts-ignore
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// @ts-ignore
mapboxgl.accessToken = MAP_BOX_API_TOKEN;

const drawStateNames = {
  acres: 'Acres',
  area: 'Area',
  lat: 'Latitude',
  lng: 'Longitude'
};

const MapDrawPage = (props: IMapDrawPageProps) => {
  const gaEventTracker = useAnalyticsEventTracker('MapDraw');
  const navigate = useNavigate();
  const { drawState, setDrawStateValue } = props;
  const mapContainer = useRef<HTMLElement>(null);
  const map = useRef<mapboxgl.Map>(null);
  const mapDraw = useRef<MapboxDraw>(null);

  const [disabledSubmitButton, setDisabledSubmitButton] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchIsVisible, setSearchIsVisible] = useState<boolean>(false);
  const [helpIsVisible, setHelpIsVisible] = useState<boolean>(false);
  const [isActiveSearch, setIsActiveSearch] = useState<boolean>(false);
  const [isActiveHelp, setIsActiveHelp] = useState<boolean>(false);
  const [isActiveClear, setIsActiveClear] = useState<boolean>(false);
  const [mapPageInfo, setMapPageInfo] = useState<MapPage | undefined>(
    undefined
  );

  const [zoom] = useState(14);
  const [currentMapMode, setCurrentMapMode] = useState<'draw' | 'select'>(
    'draw'
  );

  const customBrowserView = isIOS && isMobile && isSafari;
  const iphoneChromeView = isIOS && isMobile && isChrome;

  const [createdPolygon, setCreatedPolygon] = useState<any | null>(null);
  const [showClearConfirmModal, setShowClearConfirmModal] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [selectedCoordinates, setSelectedCoordinates] = useState<
    number[] | null
  >(null);

  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (drawState.acres) {
      if (drawState.acres > 0) {
        setDisabledSubmitButton(false);
      }
    }

    getMapPageContent().then(response => setMapPageInfo(response));
  }, [drawState.acres]);

  const handleOnExpandClick = () => {
    setExpanded(!expanded);
  };

  const onAreaCreated = (e: any) => {
    setCreatedPolygon(e.features[0]);
    setDrawStateValue('lng', e.features[0].geometry.coordinates[0][0][0]);
    setDrawStateValue('lat', e.features[0].geometry.coordinates[0][0][1]);
    setDrawStateValue(
      'acres',
      Math.round(turfArea(e.features[0]) * 0.000247 * 100) / 100
    );
  };

  const onDrawModeChange = (e: any) => {
    if (e.mode === 'simple_select') {
      setCurrentMapMode('select');
    }
  };

  const clearDraw = (confirmation: 'confirm' | 'cancel') => {
    if (confirmation !== 'confirm') {
      setShowClearConfirmModal(false);
      setIsActiveClear(!isActiveClear);

      return;
    }

    setShowClearConfirmModal(false);
    mapDraw.current?.deleteAll();
    mapDraw.current?.changeMode('custom_polygon_draw');
    setCurrentMapMode('draw');
    setDrawStateValue('acres', 0);
    setDisabledSubmitButton(true);
    setExpanded(false);
    setIsActiveClear(!isActiveClear);
  };

  const modeSimpleSelect = () => {
    mapDraw.current?.changeMode('simple_select');
    setCurrentMapMode('select');
  };

  const showOpportunities = async () => {
    gaEventTracker('submit');
    await modeSimpleSelect();

    if (disabledSubmitButton) {
      return;
    }

    if (drawState.acres) {
      await mapDraw.current?.changeMode('simple_select');
      const res = await getValidArea(
        mapDraw.current
          ?.getAll()
          // @ts-ignore
          .features[0].geometry.coordinates[0].map(item => ({
            x: item[0],
            y: item[1]
          }))
      );

      const { isLondon, isValid } = res;

      const url = await document
        .querySelector<HTMLCanvasElement>('.mapboxgl-canvas')
        ?.toDataURL('image/jpeg', 0.3);

      localStorage.setItem('screenshot', url || '');

      if (!isValid && !isLondon) {
        clearDraw('confirm');
        NotificationManager.warning(
          'Your land is not within our current catchment. However, it will be available next year'
        );

        return;
      }

      if (!isValid && isLondon) {
        clearDraw('confirm');
        NotificationManager.warning(
          'Thank you for your submission. Unfortunately, we are currently not operating in London and surrounding boroughs due to various planning and geographical constraints. We thank you once again for your submission and wish you all the best in your future land endeavours․',
          '',
          20000
        );

        return;
      }

      localStorage.setItem(
        'selectedArea',
        JSON.stringify(
          // @ts-ignore
          mapDraw.current?.getAll().features[0].geometry.coordinates[0].reduce(
            (
              acc: { [key: string]: number },
              curr: [number, number],
              idx: number
            ) => ({
              ...acc,
              [`x${idx}`]: curr[0],
              [`y${idx}`]: curr[1]
            }),
            {}
          )
        )
      );

      localStorage.setItem('acres', drawState.acres);

      navigate(opportunities);
    }
  };

  const modeDrawPolygon = () => {
    if (createdPolygon) {
      clearDraw('confirm');
    }

    mapDraw.current?.changeMode('custom_polygon_draw');
    setCurrentMapMode('draw');
  };

  const initMap = () => {
    if (map.current) {
      return;
    } // initialize map only once

    // @ts-ignore
    map.current = new mapboxgl.Map({
      container: mapContainer.current as HTMLElement,
      style: 'mapbox://styles/mapbox/satellite-streets-v11',
      center: [drawState.lng, drawState.lat],
      zoom,
      preserveDrawingBuffer: true
    });

    // @ts-ignore
    mapDraw.current = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      styles: mapDrawStyles,
      defaultMode: 'custom_polygon_draw',
      modes: {
        ...MapboxDraw.modes,
        custom_polygon_draw: CustomPolygonDraw
      }
    });

    //@ts-ignore
    map.current.addControl(mapDraw.current);
    map.current.on('draw.create', onAreaCreated);
    map.current.on('draw.update', onAreaCreated);
    map.current.on('draw.modechange', onDrawModeChange);
    map.current.on('load', function () {
      map.current?.resize();
    });
  };

  useEffect(() => {
    initMap();
  }, [map.current]);

  const [autocompleteItems, setAutocompleteItems] = useState<
    ForwardGeocodeResult[]
  >([]);

  const [autocompleteItemsLoading, setAutocompleteItemsLoading] =
    useState<boolean>(false);

  const handleInputChange = (value: string) => {
    setSearchInputValue(value);
    fetchAutocompleteItems(value);
  };

  const handleSelectItem = (item: ForwardGeocodeResult) => {
    setSearchInputValue(item.placeName);
    setSelectedCoordinates(item.center.map(Number));
    setAutocompleteItems([]);
  };

  const fetchItems = async (searchString: string) => {
    const data = await getForwardGeocode(searchString);

    setSelectedCoordinates(data.results[0].center.map(Number));

    return data.results;
  };

  const fetchAutocompleteItems = useCallback(
    debounce(async (searchString: string) => {
      if (!searchString) {
        setAutocompleteItems([]);

        return;
      }

      try {
        setAutocompleteItemsLoading(true);
        const result = await fetchItems(searchString);
        setAutocompleteItems(result);
      } catch (error) {
        console.error(error);
      } finally {
        setAutocompleteItemsLoading(false);
      }
    }, 250),
    []
  );

  const onSearchSubmit = async () => {
    const resultForMap = (await fetchItems(searchInputValue))[0];
    const res = await getValidArea(
      Array(4).fill({
        x: selectedCoordinates?.[0],
        y: selectedCoordinates?.[1]
      })
    );

    const { isLondon, isValid } = res;

    if (!isValid && !isLondon) {
      NotificationManager.warning(
        'Your land is not within our current catchment. However, it will be available next year'
      );

      return;
    }

    if (!isValid && isLondon) {
      NotificationManager.warning(
        'Thank you for your submission. Unfortunately, we are currently not operating in London and surrounding boroughs due to various planning and geographical constraints. We thank you once again for your submission and wish you all the best in your future land endeavours․',
        '',
        20000
      );

      return;
    }

    if (!resultForMap) {
      navigate('/');

      return;
    }

    if (resultForMap) {
      const { placeName, center } = resultForMap;
      searchParams.set('place', placeName);
      searchParams.set('center', center.join(','));
      setSearchParams(searchParams);
      // @ts-ignore
      map.current = new mapboxgl.Map({
        container: mapContainer.current as HTMLElement,
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        center,
        zoom
      });

      // @ts-ignore
      mapDraw.current = new MapboxDraw({
        displayControlsDefault: false,
        userProperties: true,
        styles: mapDrawStyles,
        defaultMode: 'custom_polygon_draw',
        modes: {
          ...MapboxDraw.modes,
          custom_polygon_draw: CustomPolygonDraw
        }
      });

      //@ts-ignore
      map.current.addControl(mapDraw.current);
      map.current.on('draw.create', onAreaCreated);
      map.current.on('draw.update', onAreaCreated);
      map.current.on('draw.modechange', onDrawModeChange);
      map.current.on('load', function () {
        map.current?.resize();
      });
      setDisabledSubmitButton(true);
    }

    setSearchIsVisible(!searchIsVisible);
  };

  const autocompleteList = () => {
    const items = [];

    if (autocompleteItemsLoading) {
      items.push(
        <div key="loading" className="item loading">
          Loading ...
        </div>
      );
    } else {
      autocompleteItems.forEach(item => {
        items.push(
          <div
            onClick={() => handleSelectItem(item)}
            key={item?.id}
            className="item"
          >
            {item.placeName}
          </div>
        );
      });
    }

    return (
      <div className="search-autocomplete-list">{items.map(item => item)}</div>
    );
  };

  const specsList = () =>
    Object.keys(drawState).map(keyName => (
      <div key={keyName} className="spec">
        {/* @ts-ignore */}
        <div className="name">{drawStateNames[keyName]}</div>
        <div className="value">{drawState[keyName]}</div>
      </div>
    ));

  const handleOnSearchClick = () => {
    if (helpIsVisible) {
      setHelpIsVisible(!helpIsVisible);
    }

    setSearchIsVisible(!searchIsVisible);
    setIsActiveSearch(!isActiveSearch);
    setIsActiveClear(false);
    setIsActiveHelp(false);
  };

  const handleOnHelpClick = () => {
    if (searchIsVisible) {
      setSearchIsVisible(!searchIsVisible);
    }

    setHelpIsVisible(!helpIsVisible);
    setIsActiveHelp(!isActiveHelp);
    setIsActiveSearch(false);
    setIsActiveClear(false);
  };

  return (
    <div className="map-draw-page">
      <NotificationContainer />
      <ClearConfirmModal open={showClearConfirmModal} clearDraw={clearDraw} />
      <div className="left-sidebar">
        <div className="search-block">
          <div className="search-input">
            <div className="input-inner">
              <input
                placeholder="Enter your postcode’"
                value={searchInputValue}
                onChange={e => handleInputChange(e.target.value)}
              />
              <img src={searchIcon} onClick={onSearchSubmit} alt="" />
            </div>
            {autocompleteList()}
          </div>
        </div>
        <div className="map-info-block">
          <div className="title">
            <img src={outlineIcon} alt="" />
            My Drawn Outline
          </div>
          <div className="specs">{specsList()}</div>
          <button
            onClick={showOpportunities}
            type="button"
            className={`on-footer btn-orange ${
              disabledSubmitButton ? 'disabled' : ''
            }`}
          >
            Submit
          </button>

          <div className="blue-line" />
          <div className="title">
            <img src={helpIcon} alt="" />I Need Some Help
          </div>
          {mapPageInfo && <VideoPlayer videoId={mapPageInfo.video_url} />}
          <div className="text">{mapPageInfo?.help_text}</div>
        </div>
      </div>
      <div
        className="map-block"
        style={{
          height: iphoneChromeView || customBrowserView ? '100%' : '100dvh'
        }}
      >
        <div
          className={`map-actions ${
            searchIsVisible || helpIsVisible ? 'blured' : ''
          }`}
        >
          <div className="map-actions-wrapper">
            <button
              type="button"
              className={`${currentMapMode === 'draw' ? 'active' : ''}`}
              onClick={modeDrawPolygon}
            >
              <img src={drawIcon} alt="" />
              draw
            </button>
            <button
              type="button"
              className={`${currentMapMode === 'select' ? 'active' : ''}`}
              onClick={modeSimpleSelect}
            >
              <img src={moveIcon} alt="" />
              move
            </button>
            <button type="button" onClick={() => map.current?.zoomIn()}>
              <img src={zoomInIcon} alt="" />
              zoom in
            </button>
            <button type="button" onClick={() => map.current?.zoomOut()}>
              <img src={zoomOutIcon} alt="" />
              zoom out
            </button>
            <button
              type="button"
              onClick={() => setShowClearConfirmModal(true)}
              className="clearButton"
            >
              <img src={clearIcon} alt="" />
              clear
            </button>
          </div>
          <div className="submitButtonWrapper">
            <button
              onClick={showOpportunities}
              type="button"
              className={`btn-orange ${disabledSubmitButton ? 'disabled' : ''}`}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="extra-actions">
          <button
            type="button"
            onClick={handleOnSearchClick}
            className={isActiveSearch ? 'isActiveSearch' : ''}
          >
            <SearchIcon fontSize="small" />
            Search
          </button>
          <button
            type="button"
            onClick={handleOnHelpClick}
            className={isActiveHelp ? 'isActiveHelp' : ''}
          >
            <HelpOutlineIcon fontSize="small" />
            Help
          </button>
          <button
            type="button"
            onClick={() => {
              if (helpIsVisible) {
                setHelpIsVisible(!helpIsVisible);
              } else if (searchIsVisible) {
                setSearchIsVisible(!searchIsVisible);
              }

              setShowClearConfirmModal(true);
              setIsActiveClear(!isActiveClear);
              setIsActiveSearch(false);
              setIsActiveHelp(false);
            }}
            className={isActiveClear ? 'isActiveClear' : ''}
          >
            <DeleteOutlineIcon fontSize="small" />
            Clear
          </button>
        </div>
        <div
          className={`drawnOutlineWrapper ${
            searchIsVisible ? 'hidden' : helpIsVisible ? 'blured' : ''
          }`}
        >
          <span className="expandWrapper" onClick={handleOnExpandClick}>
            My Drawn Outline
            <ExpandMoreIcon fontSize="small" className={expanded ? 'up' : ''} />
          </span>
          {expanded && <div className="specs">{specsList()}</div>}
        </div>
        <div
          className={`searchWrapper ${
            autocompleteItems.length ? 'opened' : ''
          } ${searchIsVisible ? 'visible' : ''}`}
        >
          <div className="search-input">
            <div className="input-inner">
              <input
                placeholder="eg. ‘SW19’, ‘SW19 2UG’"
                value={searchInputValue}
                onChange={e => handleInputChange(e.target.value)}
              />
              <img src={searchIcon} onClick={onSearchSubmit} alt="" />
            </div>
            {autocompleteList()}
          </div>
        </div>
        <div
          ref={mapContainer as LegacyRef<HTMLDivElement> | undefined}
          className={`map-wrapper ${
            searchIsVisible || helpIsVisible ? 'blured' : ''
          }`}
          style={{
            height: iphoneChromeView
              ? 'calc(100vh - 110px)'
              : customBrowserView
              ? '100dvh'
              : '100%'
          }}
        />
        <div className={`videoWrapper ${helpIsVisible ? 'visible' : ''}`}>
          {mapPageInfo && <VideoPlayer videoId={mapPageInfo.video_url} />}
          <div className="text">{mapPageInfo?.help_text}</div>
        </div>
      </div>
    </div>
  );
};

export default MapDrawPage;

interface IMapDrawPageProps {
  drawState: any;
  setDrawStateValue: (key: 'lng' | 'lat' | 'acres', value: number) => void;
}
