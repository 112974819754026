import { FC } from 'react';
import { Modal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { ReactComponent as Loading } from 'assets/imgs/loading1.svg';
import './loadingModal.scss';
import ProgressBar from 'lib/helpers/ProgressBar';

export interface LoadingProps {
  open: boolean;
  title: string;
  description: string;
  status?: string;
}

const LoadingModal: FC<LoadingProps> = ({
  open,
  title,
  description,
  status
}) => (
  <Modal
    open={open}
    aria-labelledby="loading-modal"
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500
    }}
  >
    <div className="loading-modal-container">
      <h3>{title}</h3>
      <div className="loading">
        <Loading />
      </div>
      {status && (
        <div className="progress-bar-container">
          <ProgressBar
            customColor="#fc8124"
            value={Number(status)}
            variant="determinate"
          />
        </div>
      )}
      <h4>We charge nothing for our services.</h4>
      <p>{description}</p>
    </div>
  </Modal>
);

export default LoadingModal;
